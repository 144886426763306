import * as React from "react";
import { useCallback } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useListTags } from "./services/hooks";
import { Tag } from "src/@generated/graphql";
import { Alert, CircularProgress } from "@mui/material";
import { useMutation } from "@apollo/client";
import { CREATE_TAG } from "./services/tags.gql";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

type TagSelectProps = {
  value: Tag[];
  onChange: (value: Tag[]) => void;
  allowCreate?: boolean;
  fetchArchived?: boolean;
};
export default function TagsSelect(props: TagSelectProps) {
  const { onChange, allowCreate, fetchArchived = false } = props;
  const [value, setValue] = React.useState<Tag[] | undefined>(
    props?.value ?? []
  );
  const [open, toggleOpen] = React.useState(false);
  const filter = createFilterOptions<Tag>();

  const [createTag, { data: createdTag, loading, error }] =
    useMutation(CREATE_TAG);

  const { client, id } = useSelector(
    (state: any) => (state?.sessionState?.authUser as Record<string, any>) ?? {}
  );
  const [searchTerm, setSearchTerm] = React.useState("");
  const [errorCode, setErrorCode] = React.useState<{
    code: string;
    name: string;
  } | null>(null);

  const {
    data: optionsData,
    isLoading: tagsLoading,
    isFetching: tagsFetching,
    refetch
  } = useListTags(searchTerm, fetchArchived);

  const debouncedRefetch = useCallback(
    debounce(() => {
      refetch();
    }, 500),
    [refetch]
  );

  return (
    <>
      <Autocomplete
        multiple
        size={"small"}
        id="tags-outlined"
        value={value}
        onChange={(_, values, inputType, selected) => {
          if (errorCode) setErrorCode(null);
          const newTagName = selected?.option?.inputText?.trim();
          if (newTagName && allowCreate) {
            createTag({
              variables: {
                input: {
                  clientId: client,
                  name: newTagName,
                  type: ["INTERVIEWER"],
                  archived: false,
                  createdBy: id
                }
              }
            })
              .then(({ data }) => {
                values.pop();
                values.push({
                  id: data?.createTag.id ?? "",
                  clientId: client,
                  name: newTagName,
                  type: ["INTERVIEWER"],
                  archived: false,
                  createdAt: new Date().toISOString(),
                  createdBy: id
                });
                setValue(values as Tag[]);
                onChange(values as Tag[]);
              })
              .catch(e =>
                setErrorCode({
                  code: e?.message,
                  name: newTagName
                })
              );
          } else {
            setValue(values as Tag[]);
            onChange(values as Tag[]);
          }
        }}
        freeSolo={!!allowCreate}
        options={optionsData?.tags || []}
        getOptionLabel={option =>
          typeof option === "string" ? option : option.name
        }
        loading={tagsLoading || tagsFetching}
        defaultValue={[]}
        limitTags={3}
        sx={{ minWidth: 200 }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (allowCreate) {
            const { inputValue } = params;

            const isExisting = options.some(
              option => inputValue === option.name
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputText: params.inputValue?.trim(),
                name: `Create New: "${params.inputValue?.trim()}"`
              });
            }
          }
          return filtered?.filter(v => !value?.some(t => t.id === v.id)) ?? [];
        }}
        renderInput={params => (
          <TextField
            {...params}
            label="Tags"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {tagsLoading || tagsFetching ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
        onInputChange={(event, newInputValue) => {
          setSearchTerm(newInputValue);
          debouncedRefetch();
        }}
      />
      {errorCode?.code === "DUPLICATE_TAG" && (
        <Alert severity="error">
          Duplicate tag! Tag ({errorCode.name}) already exists and is probably
          archived
        </Alert>
      )}
    </>
  );
}
